export const SUBMIT_MESSAGE_SUCCESS = 'Message envoyé avec succès.';
export const UNKNOWN_ERROR =
  "Une erreur est survenue lors de l'envoi du message, veuillez réessayer plus tard.";
export const FIELD_REQUIRED = '%s champ %s est requis';
export const FIELD_FORMAT_INVALID = 'Veuillez saisir %s valide';
export const RESULT_EMPTY = 'Aucun résultat trouvé';
export const INVALID_INPUT_FORMAT = 'Le format est invalide.';
export const INVALID_DATE = 'La date sélectionnée est invalide';
export const REQUIRED_INPUT = 'Ce champ est obligatoire.';
export const UNMATCHED_INPUTS =
  "Le couple n° de devis et email n'est pas correct";
